import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeHtml } from '@angular/platform-browser';
import { Observable, ReplaySubject, of, pipe, forkJoin } from 'rxjs';
import { map, tap, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

var CONF = window['CONF'];


@Injectable({ providedIn: 'root' })
export class CoreService {

	//Debug Tools: Avoid using for production logic
	component_schedule: any = null;
	headerComponent:   any = null;
	streamComponent:   any = null;
	chatComponent:     any = null;
	footerComponent:   any = null;

	constructor( public http: HttpClient, public sanitizer:DomSanitizer, public router: Router, public oauthService: OAuthService ){
		this.device_uuid //Just call the property so it's initiated.
		this.clock_tick_start()
		this.detect_ios_version()
		this.schedule_keep_synced()
		this.oauth_configure()
	}


	ios_version: number[] = null;
	detect_ios_version(){
		this.ios_version = null;
		if (/iP(hone|od|ad)/.test(navigator.platform)) {
			var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
			this.ios_version = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || '0', 10)];
		}
	}

	get device_uuid(){
		return localStorage['device_uuid'] = localStorage['device_uuid'] || 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
			/[xy]/g, (c) => {
				var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			}
		);
	}

	//Timezone Stuff
	tz = '-0500';
	get tz_offset_seconds(){ return +`${this.tz[0]}1000` * (3600*+this.tz.slice(1,3) + 60 * +this.tz.slice(3,5) ) }
	tz_convert_date(date) {
		let new_date = new Date();
		new_date.setTime( new Date(date).getTime() + this.tz_offset_seconds );
		return new_date
	}

	//Clock and Time
	clock_datetime: Date = new Date();
	clock_offset_seconds: number = 0;
	clock_tick_interval = null;
	clock_tick_event: EventEmitter<null> = new EventEmitter(); //Each time a second passes on the clock...
	clock_tick_start(){
		if( this.clock_tick_interval )
			clearInterval( this.clock_tick_interval );

		//Set the clock ticker to tick every second!
		this.clock_tick_interval = setInterval( ()=>{
			this.clock_datetime = new Date(new Date().getTime() + this.clock_offset_seconds);
			this.clock_tick_event.emit(null);
		}, 1000 );
	}


	//Sport Info
	teams = {}; //Team ID -> team
	games = {}; //Game ID -> team
	games_changed_event: EventEmitter<any> = new EventEmitter(); //new dict [g.id->changes] object.
	games_schedule_ids: number[] = [];


	//Schedule
	schedule_sync_interval = null;
	schedule_sync_subscription = null;
	schedule_keep_synced(){
		if( this.schedule_sync_interval )
			clearInterval( this.schedule_sync_interval )

		this.schedule_sync();
		this.schedule_sync_interval = setInterval(
			()=>{ this.schedule_sync() },
			1000 * 10 //10 Sec for dev
		)
	}
	schedule_sync(){
		if( this.schedule_sync_subscription )
			this.schedule_sync_subscription.unsubscribe();

		this.schedule_sync_subscription = this.http.get( `https://api.${this.top_domain}/api/v1/schedule` ).subscribe( json => {
			this.schedule_sync_subscription = null;

			let metadata = json['metadata'];

			if( metadata['server_time'] ){
				this.clock_offset_seconds = new Date(metadata['server_time']).getTime() - new Date().getTime();
				delete metadata['server_time'];
			}

			//Use the rest of metadata to fill attrs of "this"
			for( let [k, v] of Object.entries(metadata) ) this[k] = v;

			//Put Teams
			for( let t of json['teams'] ) this.teams[t.id] = t;

			let changes = {};
			for( let gid of this.games_schedule_ids )
				changes[gid] = false; //False means game was deleted (default value)

			this.games_schedule_ids = []
			for( let g of json['games'] ){
				this.games_schedule_ids.push( g.id )

				let g_was = this.games[g.id];
				this.games[g.id] = g;

				if( !(g.id in changes) ){
					changes[g.id] = true; //True means game was added
					continue
				}

				let all_keys = new Set([ ...Object.keys(g_was), ...Object.keys(g) ]);

				for( let k of all_keys )
					if( JSON.stringify(g_was[k]) !== JSON.stringify(g[k]) ){
						if( !changes[g.id] )
							changes[g.id] = []
						changes[g.id].push( k )
					}

				if( changes[g.id] === false ) //If this is the initial False value, nothing has changed, so pull it out of changes.
					delete changes[g.id];
			}

			if( Object.keys(changes).length > 0 ) //Else, nothing changed!
				this.games_changed_event.emit( changes )

		});
	}



	//Authorization and Login
	oauth_configure(){
		const authConfig: AuthConfig = {
			// Url of the Identity Provider
			issuer: 'https://accounts.google.com',
			strictDiscoveryDocumentValidation: false,

			redirectUri: `https://${this.top_domain}/oauth-silent-refresh.html`,
			clientId: '1042710374755-7cdjeb6vv4rrot9abokpfk2rdnh14dvl.apps.googleusercontent.com',

			//redirectUri: `https://api.${this.top_domain}/api/v1/oauth-redirect-endpoint`,
			//silentRefreshRedirectUri: `https://${this.top_domain}/oauth-silent-refresh.html`,
			//useSilentRefresh: true,

			responseType: 'token id_token',
			scope: 'openid profile email',
			showDebugInformation: true,
		};

		this.oauthService.configure(authConfig);
		this.oauthService.loadDiscoveryDocumentAndTryLogin();
	}
	oauth_login(){
		this.oauthService.initLoginFlowInPopup()
			.then(() => {
				// After successful login, retrieve the tokens
				this.http.post<any>( `https://api.${this.top_domain}/api/v1/oauth-options`, {
						"idToken": this.oauthService.getIdToken(),
					}, { withCredentials: true } ).subscribe( (resp) => {
						console.log(resp);
					});
			}).catch(err => {
				console.error('Login failed:', err);
			});

	}






























	//Domain Based Static Conf: camelCase, mostly for interface only variables
	logoPath: string        = CONF['logo'];
	contactMail: string     = CONF['contact_email'] || '';
	hasHighlights: boolean  = false;

	//Domain Based Static Conf: mostly for inner logic
	sport: string           = CONF['sport'].toLowerCase();
	ranking_active: boolean = false;
	alpha2: string          = "US" //CONF['country_code']
	alpha3: string          = 'USA';// window['alpha2Toalpha3'][CONF['country_code']];
	country_flag: SafeHtml  = ''


	//Premium Access
	is_premium: boolean = CONF['is_premium'];
	premium_info: any   = this.is_premium && CONF['premium_info'];
	premium_sig: any    = null;
	maintain_premium_sig( manual = false ){}
	premium_softcheck(){}
	premium_logout( logout_reason = 'manual' ){}

	//Personal Preference of this device's user
	hasToPlayMuted: boolean = true; //Autoplay only allowed without music
	base_init: boolean= false;
	base_settings: any = {
		"favourite_teams": {},

		"stream_grid_view": false,
		"stream_sort_by_time": true,

		"use_layout_theater": false,
		"chat_experimental": false,
		"show_leaderboard": !this.is_premium,

		"show_live_scores":true,
		"show_final_scores":false,
		"delete_spoilers":false,
		"commercial_breaks":1,
		"show_sharing":true, "autoplay":1,
		"turbo_edge": 13,
	};
	get settings(){
		if( !localStorage.hasOwnProperty('deviceSettings') )
			localStorage['deviceSettings'] = JSON.stringify(this.base_settings);

		let settings = JSON.parse(localStorage['deviceSettings']);

		if( !this.base_init ){
			this.base_init = true;
			for( let [k, v] of Object.entries(this.base_settings) )
				if( !settings.hasOwnProperty(k) )
					settings[k] = v;

			localStorage['deviceSettings'] = JSON.stringify(settings);
		}


		return settings;
	}
	set settings(v) { localStorage['deviceSettings'] = JSON.stringify(v); }




	createRange(number){ // Because Angular template doesn't have a normal for!
		return new Array(number).fill(0).map((n, index) => index);
	}
	countryCodeToFlag( code: string ): string {
		code = code.toUpperCase()
		if (code == "XX") //Unkown Location
			return String.fromCodePoint(128125)
		return code.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0)+127397) );
		//this.sanitizer.bypassSecurityTrustHtml(window['twemoji'].parse(this.countryCodeToFlag(msg['country_code'])))
	}




	//User Auth and Prediction/Bet Data
	rank_of(user){ return this.component_schedule.generateRankOfUser(user) }
	base_user: any = {
		avatar: "/sport-assets/24all-logo-simple.svg",
		prediction_rank: null, prediction_rank_value: null,

		prediction_success_percentage: 0.0,

		total_pending_predictions: 0, total_nulled_predictions: 0,
		total_right_predictions: 0, total_wrong_predictions: 0,

		balance: 1000, total_winnings: 0, total_loss: 0, total_unsettled: 0,
		predictions: {}, bets: {},
		last_activity: 100,
		tag_exempt: false
	}
	user:      any = this.base_user;
	chat_user: any = null;
	auth: boolean  = false;


	getMeTimeout = null;
	getMe( token = null ){
		return this.http.post<any>(
			`https://api.${this.top_domain}/api/profile/authenticate`, token, { withCredentials: true }
		).subscribe( user => {
			this.auth = user != null;
			this.user = user || this.user;

			if( this.user && this.user.tag_exempt )
				this.isTagEnabled = false;
			else
				this.setupTagRepeats() //Will only run once!

			if( this.auth ){
				if(this.getMeTimeout !== null)
					clearTimeout( this.getMeTimeout )
				this.getMeTimeout = setTimeout(()=>{this.getMe()}, 60000 * 10); //10 minutes
			}

			this.onAuthUpdate.emit(null)

		});
	}

	//Advertisement
	isTagRunning: boolean = false;
	isTagEnabled: boolean = true;
	isTagRepeated: boolean = false;
	tagChances: any[] = null; //[ [0.4: '/sport-assets/brtg.js'], ... ]
	tagRepeats: number[] = null; //[0, 1200, 60, ...]
	tagsRan: Set<string> = new Set();

	activateTag(){}
	setupTagRepeats( force = false ){}

	//Routing Tool
	top_domain:     string = location.hostname.split(".").slice(-2).join(".")
	get url_path(): string { return window.location.pathname; }
	redirectTo( u, scroll = false ){
		if( typeof(u) == "string" ) u = [u];
		this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
			this.router.navigate(u)

			if( scroll )
				setTimeout(()=>{
					window.scrollTo({ top: document.getElementsByTagName('router-outlet')[0]['offsetTop'] - 50, behavior: 'smooth' })
				}, 10);
		});
	}

	//General Events
	onAuthRequired:  EventEmitter<null>   = new EventEmitter();
	onAuthUpdate:    EventEmitter<null>   = new EventEmitter(); //Login / Logout / New Data / ...
	onChatBoxUpdate: EventEmitter<string> = new EventEmitter(); //New Box uid (can repeate)


	//Sectioned Data
	//teams: any[] = [];
	abr2teams: any = {};
	//games: any[] = [];
	id2games: any = {};
	links: any[] = []; id2links: any = {};
	stats: any = null;

	feeds: any = {}; //content_id -> link_id[]
	feedStatusToIcon = {
		"R":"history",
		"L":"smart_display",
		"P":"hourglass_top",
		"E":"bug_report",
		"M":"hourglass_disabled",
		"S":"delete_forever",
		"U":"search",
	}
	feedStatusToText = {
		"R":"Replay",
		"L":"Live",
		"P":"Planned",
		"E":"Error",
		"M":"Delayed",
		"S":"Deleted",
		"U":"Unspecified",
	}


	//site state, potentioally give away with events instead of keeping them here!
	overlayImage: any       = null;
	topPUsers:   any[]      = [];
	topSUsers:   any[]      = [];
	streamList: any[]       = []; //Extra Streams
	recentUsers: number     = null;
	activeUsers: number     = null;
	predictors: number      = null;
	bettors: number         = null;
	breakDetection: boolean = false;
	downloadActive: boolean = false;
	betsimActive: boolean   = false;
	totalUsers: number      = null;
	autoPlayed: boolean     = false;

	syncState(){

		this.http.get<any>( `https://api.${this.top_domain}/api/v1/sport/stateshot`, {observe: 'response'} ).subscribe( response => {
			var json = response.body;

			let conf = json.configs;
			if( conf ){

				this.tz             = conf.timezone
				this.totalUsers     = conf.total_users
				this.recentUsers    = conf.prediction_recent_users
				this.activeUsers    = conf.prediction_active_users
				this.predictors     = conf.prediction_participants
				this.bettors        = conf.simulator_participants
				this.ranking_active = conf.ranking_active
				this.betsimActive   = conf.betsim_active
				this.tagChances     = conf.tag_chances;
				this.tagRepeats     = conf.tag_repeats;
				this.overlayImage   = conf.overlay_image;
				this.topPUsers      = conf.top_prediction_users
				this.topSUsers      = conf.top_simulator_users

				//if( !this.teams || this.teams.length == 0 ){
					//this.teams = conf.teams
					//for( let t of this.teams )
					//	this.abr2teams[t.abr] = t;
				//}
				if( !this.contactMail ) this.contactMail = conf.contact_mail;

				this.onChatBoxUpdate.emit( conf.bungee_box_uid );
			}

			if( !this.scheduleLocked ){
				this.assignSchedule( json.games, json.links );
				//this.games_changed_event.emit(null);
			}
		});

	}

	scheduleLocked = false;
	assignSchedule( games, links ){
		this.games = games;
		//for( let g of this.games ) this.id2games[g.id] = g;

		this.links = links;
		for( let l of this.links ) this.id2links[l.id] = l;

		let content_id_2_game = {}
		for( let g of games )
			for( let c of g.content_id_list )
				content_id_2_game[c] = g

		let feeds = {};
		for( let l of links ){
			let game = content_id_2_game[l['content_id']] || null;

			let f = feeds[l['content_id']];
			if( !f )
				f = feeds[l['content_id']] = {
					"type"     : "_feed",
					"id"       : l['content_id'],
					"title"    : l['content_id'].split('|').slice(-1)[0],
					"game_id"  : game && game.id,
					"datetime" : game ? game.start_datetime : l['event_datetime'],
					"status"   : l.status, //General Link Status
					"tags"     : new Set(['geolimit']),
					"link_ids" : [],
				}

			f["link_ids"].push( l['id'] );

			if( l.geo == "+" )
				f['tags'].delete('geolimit')

			if( l.status == "L" ){
				f['tags'].add('live')
				f['status'] = "L" //Live trumps all other statuses
			}

			if( l.status == "R" && f['status'] != "L" )
				f['status'] = "R"

			if( l.provider == "ESPN" )
				f['tags'].add('espnplus')

			if( l.title == "French" ) //"RELP".includes( l.status ) &&
				f['tags'].add('fr')

		}

		for( let f of Object.values(feeds) )
			f['link_ids'].sort((lid1, lid2)=>{
				let l1 = this.id2links[lid1];
				let l2 = this.id2links[lid2];

				//Watchable Feeds take the first precedence of course!
				if( l1.url === false ) return (l2.url === false) ? 0: +1
				if( l2.url === false ) return -1

				//NEXT: Not expired links!
				if( l1.status === "E" ) return (l2.status === "E") ? 0: +1
				if( l2.status === "E" ) return -1

				//NEXT: Prefer worldy watchable feeds!
				if( l1.geo === "+" ) return (l2.geo === "+") ? 0: -1;
				if( l2.geo === "+" ) return +1;


				let l1score = 0, l2score = 0;

				if( l1.geo.startsWith("-") && l1.geo.includes( this.alpha3 + "+" ) )
					l1score = 1; //Prefer being whitelisted
				if( l2.geo.startsWith("-") && l2.geo.includes( this.alpha3 + "+" ) )
					l2score = 1; //Prefer being whitelisted

				if( l1.geo.startsWith("+") && l1.geo.includes( this.alpha3 + "-" ) )
					l1score = -1; //Avoid being Blacklisted
				if( l2.geo.startsWith("+") && l2.geo.includes( this.alpha3 + "-" ) )
					l2score = -1; //Avoid being Blacklisted

				return l2score - l1score
			})

		this.feeds = feeds;
	}




	streamCurrentGameId = null;
	streamCurrentHighlight = null;

	getPlacehoderVideo(): SafeUrl {
		let SVG = `<svg width="1920" height="1080" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080"><path d="M-1.5-1.5h1920v1080H-1.5z"/></svg>`
		return this.sanitizer.bypassSecurityTrustUrl( 'data:image/svg+xml;base64,' + btoa(SVG) );
	}



























	/*
		Generic Tools:
	*/
	//Load Custom JS Libraries
	//Ex: await scripts_load_urls('assets/test.js').toPromise();
	scripts_loaded: any = {}; //{ [url: string]: ReplaySubject<any> } = {};
	scripts_load_urls(...urls: string[]): Observable<any> {
		return forkJoin(urls.map(url=>{return this.scripts_load_url(url)}));
	}
	scripts_load_url(url: string): Observable<any> {
		if (this.scripts_loaded[url]) {
			return this.scripts_loaded[url].asObservable();
		}

		this.scripts_loaded[url] = new ReplaySubject();

		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = url;
		script.onload = () => {
			this.scripts_loaded[url].next(null);
			this.scripts_loaded[url].complete();
		};

		document.body.appendChild(script);

		return this.scripts_loaded[url].asObservable();
	}

}
